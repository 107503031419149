import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ShareModule } from "./common/share/share.module";
import { MessageBoxModule } from "./common/message-box/message-box.module";
import { IonicStorageModule } from "@ionic/storage-angular";
import { AppConfig } from "./common/config/app.config";
import { FullSpinnerInterceptor } from "./common/full-spinner/full-spinner.interceptor";
import { AppSettingsService } from "./common/services/app-settings/app-settings.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { Drivers } from "@ionic/storage";
import { QuillModule, QUILL_CONFIG_TOKEN } from "ngx-quill";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SnackbarModule } from "./common/alerts/snackbar/snackbar.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export const appSettingFactory = (configService: AppSettingsService) => {
  return () => configService.loadConfig();
};

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ShareModule,
    MessageBoxModule,
    MatSnackBarModule,
    SnackbarModule,
    OverlayModule,
    IonicStorageModule.forRoot({
      name: AppConfig.dbname,
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    QuillModule.forRoot(),
    NgbModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FullSpinnerInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appSettingFactory,
      deps: [AppSettingsService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
