import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Event, NavigationStart, Router } from "@angular/router";
import { Storage } from "@ionic/storage";
import { format } from "date-fns";
import { ApiService } from "./common/config/api.service";
import { AppSettingsService } from "./common/services/app-settings/app-settings.service";
import { ConnectionService } from "./common/services/connection/connection.service";
import { DataService } from "./common/services/data/data.service";
import { NavigationService } from "./common/services/Navigation/navigation.service";
import { UpdateService } from "./common/services/update/update.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  launched = false;
  showLaunchBtn = false;
  btnLoading = false;
  successMessage = false;
  launchcode = {
    launchcode1: "",
    launchcode2: "",
    launchcode3: "",
    launchcode4: "",
  };
  launchTimer: any = "";
  successMessageText = "";
  launchDateTime = "";
  @ViewChild("input1", { static: false }) input1!: ElementRef;
  @ViewChild("input2", { static: false }) input2!: ElementRef;
  @ViewChild("input3", { static: false }) input3!: ElementRef;
  @ViewChild("input4", { static: false }) input4!: ElementRef;
  @ViewChild("animation", { static: false }) animation!: ElementRef;
  constructor(
    public storage: Storage,
    public update: UpdateService,
    public data: DataService,
    public router: Router,
    public connection: ConnectionService,
    public appSetting: AppSettingsService,
    private renderer: Renderer2,
    public apiService: ApiService,
    public navigationback: NavigationService
  ) {
    this.navigationback.startSaveHistory();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // // console.log(event);animation
      }
    });
  }
  ngAfterViewInit(): void {
    // this.data.showLaunchModal = true;
    // setTimeout(() => {
    //   this.input1.nativeElement.focus();
    // }, 300);
  }

  ngOnInit(): void {
    this.createStorage();
    this.data.getToken();
    this.update.check();
    this.connection.networkStatus();
    // this.getLaunchinfo(true);
  }

  getLaunchinfo(val?) {
    const options = {
      hideFullSpinner: true,
      hidejwt: true,
      hideErrorMethod: true,
    };
    this.apiService.getLaunchInfo(options).subscribe((success: any) => {
      // // console.log(success);
      if (success.isLaunched) {
        this.data.showLaunchModal = false;
        this.launched = true;
        this.launchDateTime = success.launchDateTime;
      } else {
        this.data.showLaunchModal = true;
        if (val) {
          setTimeout(() => {
            this.input1.nativeElement.focus();
          }, 300);
        }
        this.launched = false;
        this.checkLaunchInfo();
      }
    });
  }
  getLaunchinfoWithModal(val?) {
    const options = {
      hideFullSpinner: true,
      hidejwt: true,
      hideErrorMethod: true,
    };
    this.apiService.getLaunchInfo(options).subscribe((success: any) => {
      // // console.log(success);
      if (success.isLaunched) {
        this.launched = true;
        this.launchDateTime = success.launchDateTime;
      } else {
        this.data.showLaunchModal = true;
        if (val) {
          setTimeout(() => {
            this.input1.nativeElement.focus();
          }, 300);
        }
        this.launched = false;
        this.checkLaunchInfo();
      }
    });
  }

  checkLaunchInfo() {
    clearTimeout(this.launchTimer);
    this.launchTimer = setTimeout(() => {
      this.getLaunchinfoWithModal();
    }, 2000);
  }

  async createStorage() {
    await this.storage.create();
  }

  doInput(val) {
    switch (val) {
      case 1:
        if (this.launchcode.launchcode1 !== "") {
          this.input2.nativeElement.focus();
        }
        break;
      case 2:
        if (this.launchcode.launchcode2 !== "") {
          this.input3.nativeElement.focus();
        }
        break;
      case 3:
        if (this.launchcode.launchcode3 !== "") {
          this.input4.nativeElement.focus();
        }
        break;
      case 4:
        if (this.launchcode.launchcode4 !== "") {
          this.checkValid();
        }
        break;

      default:
        break;
    }
  }

  checkValid() {
    const lcode =
      this.launchcode.launchcode1 +
      this.launchcode.launchcode2 +
      this.launchcode.launchcode3 +
      this.launchcode.launchcode4;
    if (lcode === this.appSetting.environment.lcode) {
      this.btnLoading = true;
      clearTimeout(this.launchTimer);
      setTimeout(() => {
        this.btnLoading = false;
        this.showLaunchBtn = true;
      }, 2000);
    } else {
      setTimeout(() => {
        this.launchcode = {
          launchcode1: "",
          launchcode2: "",
          launchcode3: "",
          launchcode4: "",
        };
      }, 100);

      this.input1.nativeElement.focus();
    }
  }
  launchNow() {
    this.btnLoading = true;
    this.renderer["addClass"](this.animation.nativeElement, "animate__fadeOut");
    this.doLaunch();
  }

  doLaunch() {
    setTimeout(() => {
      this.successMessage = true;
      this.successMessageText = "Launching";
      this.renderer["removeClass"](
        this.animation.nativeElement,
        "animate__fadeOut"
      );
      this.renderer["addClass"](
        this.animation.nativeElement,
        "animate__fadeIn"
      );
    }, 1100);
    // const obj = {
    //   isLaunched: true,
    //   launchDateTime: format(
    //     new Date(),
    //     this.appSetting.environment.serverDateFormatWithTime
    //   ),
    // };
    const obj = {
      isLaunched: true,
      launchDateTime: "08/25/2021 00:00",
    };
    const options = {
      hideFullSpinner: true,
      hidejwt: true,
      hideErrorMethod: true,
    };
    this.apiService.setLaunchInfo(obj, options).subscribe((success: any) => {
      // // console.log(success);
      if (success.isLaunched) {
        // this.data.showLaunchModal = false;
        this.launched = true;
        this.launchDateTime = success.launchDateTime;
        this.successMessageText = "Successfully Launched";
        setTimeout(() => {
          this.renderer["removeClass"](
            this.animation.nativeElement,
            "animate__fadeIn"
          );
          this.renderer["addClass"](
            this.animation.nativeElement,
            "animate__fadeOut"
          );
        }, 2200);
        setTimeout(() => {
          this.successMessage = false;
          this.btnLoading = false;
          this.launched = true;
          this.renderer["removeClass"](
            this.animation.nativeElement,
            "animate__fadeOut"
          );
          this.renderer["addClass"](
            this.animation.nativeElement,
            "animate__fadeIn"
          );
        }, 3400);
      } else {
        this.data.showLaunchModal = true;
        this.launched = false;
        // this.checkLaunchInfo();
      }
    });
  }

  explore() {
    if (this.launched) {
      this.data.showLaunchModal = false;
    }
  }
  checkdelete(event, position) {
    if (event.key === "Backspace") {
      switch (position) {
        case 1:
          break;
        case 2:
          this.input1.nativeElement.focus();
          break;
        case 3:
          this.input2.nativeElement.focus();
          break;
        case 4:
          this.input3.nativeElement.focus();
          break;
        default:
          break;
      }
    }
  }
}
