<div class="form-group" *ngIf="icononly === 'false'">
    <div class="d-flex align-items-center pb-1" *ngIf="label !== ''">
        <label *ngIf="label !== ''" class="lh16">{{label}} <span class="text-danger" *ngIf="mandatory">
                *</span></label>
        <div class="ml-auto text-danger lh15" *ngIf="errorText !== ''">
            <small>{{errorText}}</small>
        </div>
        <div class="ml-auto text-danger lh15" *ngIf="value === '' && mandatory && errorTrueNow ">
            <small>mandatory</small>
        </div>

        <!-- <a mat-icon-button class="i-btn-small ml-auto text-center" *ngIf="helptext" [matTooltip]="helptext">
            <ion-icon name="help-circle-outline"></ion-icon>
        </a> -->
    </div>
    <!-- <label for="" *ngIf="label !== ''" >{{label}}</label> -->
    <div class="input-group">

        <input appNoEmoji [appUpperCase]="upperCase" autocomplete="none" type="text" class="form-control pr28px"
            [(ngModel)]="xvalue" name="{{name}}" placeholder="{{placeholder}}"
            [ngClass]="{'border-danger': xvalue === '' && errorTrueNow}" [disabled]="btnClickedNow" (blur)="blured()"
            #inputbox (keydown)="checkenter($event)" #selectInput [maxlength]="maxlength">
        <div class="input-group-append d-flex in-1" *ngIf="value !== '' && !btnClickedNow">
            <ng-container *ngIf="search === 'true'">
                <a mat-button class="i-btn" (click)="onSearch()" matTooltip="Search" matTooltipPosition="right">
                    <ion-icon name="search-outline"></ion-icon>
                </a>
            </ng-container>
            <ng-container *ngIf="value && search==='false'">
                <a mat-button tabIndex="-1" class="i-btn" (click)="doClear()" matTooltip="Clear"
                    matTooltipPosition="right">

                    <ion-icon name="close-outline"></ion-icon>
                </a>
            </ng-container>

        </div>
    </div>
</div>

<div class="form-group" *ngIf="icononly === 'true'">
    <div class="input-group">
        <input appNoEmoji autocomplete="none" type="text" class="form-control pr28px" [(ngModel)]="xvalue"
            name="{{name}}" placeholder="{{placeholder}}" [ngClass]="{'border-danger': xvalue === '' && errorTrueNow}"
            [disabled]="btnClickedNow">
        <div class="input-group-append d-flex in-1" *ngIf="value !== '' && !btnClickedNow">
            <ng-container *ngIf="search === 'true'">
                <a mat-button class="i-btn" (click)="onSearch()" matTooltip="Search" matTooltipPosition="right">
                    <ion-icon name="search-outline"></ion-icon>
                </a>
            </ng-container>

            <!-- <a mat-button class="i-btn" (click)="value = ''" matTooltip="Clear" matTooltipPosition="right">
                    <mat-icon>clear</mat-icon>
                    <ion-icon name="close-outline"></ion-icon>
                </a> -->

        </div>
    </div>
</div>