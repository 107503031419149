import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PreApplication } from '../../config/app.classes';
import { DataService } from '../../services/data/data.service';
import { DropDownService } from '../../services/drop-down/drop-down.service';
import { ValueAccessorBase } from '../value-accessor-base';

@Component({
  selector: 'app-multi-select-premium',
  templateUrl: './multi-select-premium.component.html',
  styleUrls: ['./multi-select-premium.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectPremiumComponent,
      multi: true,
    },
  ],
})
export class MultiSelectPremiumComponent extends ValueAccessorBase<string> implements OnInit {

  @Input() public label: string = '';
  @Input() public name: string = '';
  @Input() public type: string = ''
  @Input() public placeholder: string = '';
  @Input() public mandatory = false;
  errorTrueNow = false;
  @Output() selectPremiumError: any = new EventEmitter();
  @Input() selectPremiumErrorMsg: string = '';
  @Input()
  public ProductSelect: boolean = false;

  @Input()
  set errorTrue(errorTrue: boolean) {
    this.errorTrueNow = errorTrue || false;
  }
  get errorTrue() {
    return this.errorTrueNow;
  }
  btnClickedNow = false;
  @Input()
  set btnClicked(btnClicked: boolean) {
    this.btnClickedNow = btnClicked || false;
  }
  get btnClicked() {
    return this.btnClickedNow;
  }


  // Specific to template

  preApplication = new PreApplication();
  productLength = 0;
  selectedValue: any = [];
  selectedPreCount = 0;
  @Input()
  set dataSet(dataSet: any) {
    this.preApplication = dataSet || new PreApplication();
  }

  get dataSet() {
    return this.preApplication;
  }

  // dropdown template
  @ViewChild('inputbox', { static: true }) inputbox!: any;
  @ViewChild('optionselecttemplate') optionselecttemplate!: TemplateRef<any>;

  constructor(
    public dropDownService: DropDownService,
    public vcr: ViewContainerRef,
    public data: DataService
  ) {
    super();
    this.getValue().subscribe((xvalue) => {

    });
  }

  async ngOnInit() {

  }

  async optionSelected(event: any, preIndex: any, proIndex: any, orgIndex: any) {
    debugger
    let selectedCount = 0;
    this.selectedPreCount = 0;
    let premiumAmount = this.preApplication.lstentPreApplicationGroupOrgLink[orgIndex].lstentPreApplicationGroupOrgProductLink[proIndex].plstentPreapplicationProductPremiumDetailLink[preIndex].premiumAmount;
    // console.log(this.preApplication.lstentPreApplicationGroupOrgLink[orgIndex].lstentPreApplicationGroupOrgProductLink[proIndex].plstentPreapplicationProductPremiumDetailLink[preIndex]);
    let sumInsured = this.preApplication.lstentPreApplicationGroupOrgLink[orgIndex].lstentPreApplicationGroupOrgProductLink[proIndex].plstentPreapplicationProductPremiumDetailLink[preIndex].sumInsuredAmount;

    let orgLink: any = this.preApplication.lstentPreApplicationGroupOrgLink;
    for (let i = 0; i < orgLink.length; i++) {
      let proLink = orgLink[i].lstentPreApplicationGroupOrgProductLink;
      for (let j = 0; j < proLink.length; j++) {
        let preLink = proLink[j].plstentPreapplicationProductPremiumDetailLink;
        for (let k = 0; k < preLink.length; k++) {
          this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].plstentPreapplicationProductPremiumDetailLink[k].isSelected = 'false';
          let newPremiumAmount = this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].plstentPreapplicationProductPremiumDetailLink[k].premiumAmount;
          if (premiumAmount === newPremiumAmount && event.checked) {
            this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].plstentPreapplicationProductPremiumDetailLink[k].isSelected = 'true';
            selectedCount = selectedCount + 1;
            console.log(selectedCount);
            this.ProductSelect = false;
          }

        }
      }
    }
    // this.selectedPreCount = selectedCount;

    const obj = {
      premiumAmount: this.preApplication.lstentPreApplicationGroupOrgLink[orgIndex].lstentPreApplicationGroupOrgProductLink[proIndex].plstentPreapplicationProductPremiumDetailLink[preIndex].premiumAmount,
      premiumCode: this.preApplication.lstentPreApplicationGroupOrgLink[orgIndex].lstentPreApplicationGroupOrgProductLink[proIndex].plstentPreapplicationProductPremiumDetailLink[preIndex].premiumCode
    }
    this.setSelectedValue(obj, event.checked, premiumAmount, sumInsured);
  }

  async setSelectedValue(obj, type, premiumAmount, sumInsured) {
    await this.checkProductLength();
    // console.log(this.productLength);
    debugger;
    // this.selectedOrgCount = count;
    // this.selectedValue = [];
    let index = this.selectedValue.map(e => e.premiumCode).indexOf(obj.premiumCode);
    if (type) {
      if (index === -1) {
        this.selectedValue = [];
        this.selectedValue.push(JSON.parse(JSON.stringify(obj)));
      }
    } else {
      this.selectedValue.splice(index, 1);
    }
    // // console.log(this.selectedValue);
    console.log(this.selectedPreCount);

    if (this.selectedValue.length > 0 && this.productLength === this.selectedPreCount) {
      this.value = premiumAmount;
      this.preApplication.sumInsuredAmount = sumInsured;
      // this.data.clearMessage();
      this.selectPremiumError.emit('');
    } else {
      this.value = "";
      // this.data.messageBoxExpandTrue = true;
      this.selectPremiumError.emit(this.selectPremiumErrorMsg);
      this.preApplication.sumInsuredAmount = "";
    }
  }


  async openSelectOptionDialog() {
    debugger
    if (this.ProductSelect) {
      let orgLink: any = this.preApplication.lstentPreApplicationGroupOrgLink;
      for (let i = 0; i < orgLink.length; i++) {
        let proLink = orgLink[i].lstentPreApplicationGroupOrgProductLink;
        for (let j = 0; j < proLink.length; j++) {
          let preLink = proLink[j].plstentPreapplicationProductPremiumDetailLink;
          for (let k = 0; k < preLink.length; k++) {
            if (
              this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].plstentPreapplicationProductPremiumDetailLink[k].isSelected === 'true' && this.type === 'new' && this.ProductSelect === true
            ) {

              this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].plstentPreapplicationProductPremiumDetailLink[k].isSelected = 'false';
            }
            if (
              this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].plstentPreapplicationProductPremiumDetailLink[k].isSelected === 'true' && this.type === 'edit' && this.ProductSelect === true
            ) {

              this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].plstentPreapplicationProductPremiumDetailLink[k].isSelected = 'false';
            }
          }
        }
      }
    }
    await this.checkProductLength();
    if (!this.dropDownService.isOpen && this.productLength > 0) {
      this.dropDownService.openDropdown(
        this.inputbox.nativeElement,
        this.optionselecttemplate,
        this.vcr
      );
    }
  }

  checkProductLength() {
    return new Promise((resolve) => {
      this.productLength = 0;
      this.selectedPreCount = 0;
      let orgLink: any = this.preApplication.lstentPreApplicationGroupOrgLink;
      for (let i = 0; i < orgLink.length; i++) {
        let proLink = orgLink[i].lstentPreApplicationGroupOrgProductLink;
        for (let j = 0; j < proLink.length; j++) {
          if (proLink[j].isSelected === 'true') {
            this.productLength = this.productLength + 1;
            let preLink = proLink[j].plstentPreapplicationProductPremiumDetailLink
            for (let k = 0; k < preLink.length; k++) {
              if (preLink[k].isSelected === 'true') {
                this.selectedPreCount = this.selectedPreCount + 1;
              }

            }
          }
        }
      }
      resolve(true);
    })
  }

}
