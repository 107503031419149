<ol class="breadcrumb p-0">
    <ng-container *ngFor="let path of items; let i = index">
        <li class="breadcrumb-item">
            <ng-container *ngIf="i > 0">
                <ion-icon name="chevron-forward-outline"></ion-icon>
            </ng-container>
            <ng-container *ngIf="path.link !== ''">
                <a [routerLink]="path.link">
                    <div class="d-flex align-items-center">
                        <div class="back-icon">
                            <ion-icon name="arrow-back-outline"></ion-icon>
                        </div>
                        <div>{{path.name}}</div>
                    </div>
                </a>
            </ng-container>
            <ng-container *ngIf="path.link === ''">
                <span>{{path.name}}</span>
            </ng-container>
        </li>
    </ng-container>
</ol>