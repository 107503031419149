import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationStart, Router } from "@angular/router";
import { Storage } from "@ionic/storage";
import { Observable, Subject, throwError } from "rxjs";
import { CommonAlertComponent } from "../../alerts/common-alert/common-alert.component";
import { SessionAlertComponent } from "../../alerts/session-alert/session-alert.component";
import { Message } from "../../config/app.classes";
import { AppConfig } from "../../config/app.config";
import { AppSettingsService } from "../app-settings/app-settings.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { catchError, finalize, takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataService {
  pattern = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%|"'~^&*-]).{8,}$/;
  readonly defaultOptions = {
    hideErrorMethod: false,
    hideFullSpinner: false,
    hidejwt: false,
  };
  public SessionId: any;
  url = "";
  basePath = "";
  loadingTrue = false;
  btnClicked = false;
  token = "";
  tokens = "";
  message = new Message();
  messageBoxExpandTrue = false;
  userDefaultLandingPage = "";
  showLaunchModal = false;
  selectPremiumerrorMsg = '';
  // message = {
  //   errorMessage: [
  //     { msgID: 0, msgType: 0, msgDescription: "Hello This Error" },
  //   ],
  // };
  // messageBox = new MessageBoxComponent();

  bottom = "0";
  responsiveType = "desktop";
  displayNameMap = new Map([
    [Breakpoints.TabletPortrait, "tabletP"],
    [Breakpoints.TabletLandscape, "tabletL"],
    [Breakpoints.WebLandscape, "desktopL"],
    [Breakpoints.WebPortrait, "desktopP"],
  ]);
  destroyed = new Subject<void>();
  constructor(
    public http: HttpClient,
    public storage: Storage,
    public dialog: MatDialog,
    public router: Router,
    public appSetting: AppSettingsService,
    private snackBar: MatSnackBar,
    public breakpointObserver: BreakpointObserver
  ) {
    this.basePath = this.appSetting.environment.basePath;
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.message = new Message();
        this.messageBoxExpandTrue = false;
        const urls = [
          "/",
          "/login/company",
          "/login/individual",
          "/forgot-username/individual",
          "/forgot-username/company",
          "/forgot-password/individual",
          "/forgot-password/company",
          "/set-password",
          "reset-password/company",
          "reset-password/individual",
        ];
        const index = urls.indexOf(val.url);
        if (index !== -1) {
          this.token = "";
          this.storage.clear();
        }
        // if (this.router.url === this.userDefaultLandingPage) {
        //   // console.log('logout alert');
        // }
        this.url = val.url;
        // // console.log(val);
      }
    });

    breakpointObserver
      .observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.WebLandscape,
        Breakpoints.WebPortrait,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.responsiveType = this.displayNameMap.get(query) ?? "Unknown";
            // // console.log(this.responsiveType);
          }
        }
      });
    // // console.log(this.responsiveType);
  }

  async getToken() {
    await this.storage.get("userData").then((val) => {
      if (val) {
        this.token = val.keyToken;
        let landingpage = val.userDefaultLandingPage;
        this.userDefaultLandingPage = "/" + landingpage;
      }
    });
  }
  checkToken(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (this.token !== '') {
        resolve(true);
      } else {
        await this.storage.get('userData').then((val) => {
          if (val) {
            this.token = val.keyToken;
            resolve(true);
          }
        });
      }
    });
  }
  setOptions(options) {
    for (const opPath of Object.keys(this.defaultOptions)) {
      options[opPath] === undefined
        ? (options[opPath] = this.defaultOptions[opPath])
        : "";
    }
    return options;
  }
  serviceStarted() {
    // // console.log('Service Call Started');
    this.message = new Message();
    this.loadingTrue = true;
    this.btnClicked = true;
  }
  serviceCompleted() {
    // // console.log('Service Call Completed');
    this.loadingTrue = false;
    this.btnClicked = false;
  }

  getData(path, options?): Observable<any> {
    let header = {};
    if (options) {
      if (options.hidejwt) {
        header = new HttpHeaders({
          Spinner: options.hideFullSpinner ? "" : "true",
        });
      } else {
        header = new HttpHeaders({
          Authorization: "Bearer " + this.token,
          Spinner: options.hideFullSpinner ? "" : "true",
        });
      }
    } else {
      header = new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Spinner: options.hideFullSpinner ? "" : "true",
      });
    }
    return this.http.get(this.basePath + path, { headers: header });
  }

  getDataAnonymous(path, options?): Observable<any> {
    const header = new HttpHeaders({
      Spinner: options.hideFullSpinner ? "" : "true",
    });
    return this.http.get(this.basePath + path, { headers: header });
  }

  postData(path, body, options?) {
    let header = {};
    if (options) {
      if (options.hidejwt) {
        header = new HttpHeaders({
          Spinner: options.hideFullSpinner ? "" : "true",
        });
      } else {
        header = new HttpHeaders({
          Authorization: "Bearer " + this.token,
          Spinner: options.hideFullSpinner ? "" : "true",
        });
      }
    } else {
      header = new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Spinner: options.hideFullSpinner ? "" : "true",
      });
    }
    return this.http.post(this.basePath + path, body, { headers: header });
  }

  postDataAnonymous(path, body, options?) {
    const header = new HttpHeaders({
      Spinner: options.hideFullSpinner ? "" : "true",
    });
    return this.http.post(this.basePath + path, body, { headers: header });
  }

  postPDFData(path, body, options?) {
    const header = new HttpHeaders({
      Authorization: "Bearer " + this.token,
      Spinner: options.hideFullSpinner ? "" : "true",
    });
    return this.http.post(this.basePath + path, body, {
      headers: header,
      responseType: "blob",
    });
  }

  opendocument(body, options?): Observable<any> {
    this.serviceStarted();
    options === undefined
      ? (options = this.defaultOptions)
      : (options = this.setOptions(options));
    return this.postPDFData("common/opendocument", body, options).pipe(
      finalize(() => this.serviceCompleted()),
      catchError((err) => {
        options.hideErrorMethod ? "" : this.errorMethod(err);
        return throwError(err);
      })
    );
  }

  // clearMessage
  clearMessage() {
    this.message = new Message();
    this.messageBoxExpandTrue = false;
  }

  // Error Methods
  errorMethod(err) {
    // // console.log(err);
    switch (err.status) {
      case 400:
        this.message = err.error;
        setTimeout(() => {
          this.messageBoxExpandTrue = true;
        }, 400);
        break;
      case 401:
        this.confirmSessionOut("");
        break;
      case 500:
        this.internalServerError(err.error);
        break;
      case 501:
        this.internalServerError(err.error);
        break;
      case 503:
        this.internalServerError(err.error);
        break;
      case 0:
        if (err.statusText === "Unknown Error") {
          this.internalServerError("");
          break;
        }
      default:
        break;
    }
  }

  internalServerError(msg) {
    // // console.log(msg);
    let xmsg = "";
    msg !== "" && msg != null
      ? (xmsg = msg)
      : (xmsg =
        "Bula, We are currently facing some technical issues right now. Please try again after some time.");
    // // console.log(xmsg);
    const dialogRef = this.dialog.open(CommonAlertComponent, {
      width: "450px",
      disableClose: true,
      data: {
        msg: xmsg,
        trueBtnText: "Ok",
        trueBtnColor: "danger",
        trueRaised: true,
        falseBtnText: "",
        falseBtnColor: "danger",
        showTrue: true,
        showFalse: false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  confirmSessionOut(msg) {
    let xmsg = "";
    msg !== ""
      ? (xmsg = msg)
      : (xmsg = "Your session expired. Please login again to continue.");
    const dialogRef = this.dialog.open(SessionAlertComponent, {
      width: "350px",
      disableClose: true,
      data: {
        msg: xmsg,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigateByUrl("/");
      }
    });
  }
  successMessage(msg) {
    this.snackBar.open(msg, "Close", {
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ["bg-info"],
      duration: 3000,
    });
  }

  errorMesaageOnly(msg) {
    this.constructErrorMsg(msg);
  }
  errorMesaage(msg) {
    if (msg) {
      this.selectPremiumerrorMsg = msg;
    }
  }
  constructErrorMsg(val) {
    this.message.hasError = true;
    this.message.errorMessage = [];
    const obj = {
      msgID: 0,
      msgType: 0,
      msgDescription: val,
    };
    this.message.errorMessage.push(obj);
    setTimeout(() => {
      this.messageBoxExpandTrue = true;
    }, 400);
  }
}
