<div class="form-group">
    <div class="d-flex align-items-center pb-1" *ngIf="label !== ''">
        <label *ngIf="label !== ''" class="lh16">{{label}} <span class="text-danger" *ngIf="mandatory">
                *</span></label>
        <div class="ml-auto text-danger lh15" *ngIf="errorText !== ''">
            <small>{{errorText}}</small>
        </div>
        <div class="ml-auto text-danger lh15" *ngIf="value === '' && mandatory && errorTrueNow">
            <small>mandatory</small>
        </div>
        <!-- <div class="ml-auto text-danger lh15" *ngIf="value === ''">
            <small>{{selectProErrorMsg}}</small>
        </div> -->

    </div>
    <div class="input-group" (click)="openSelectOptionDialog()">
        <input appNoEmoji autocomplete="none" type="text" class="form-control pr28px" [(ngModel)]="value"
            name="{{name}}" placeholder="{{placeholder}}" [ngClass]="{'border-danger': value === '' && errorTrueNow}"
            [disabled]="btnClickedNow" #inputbox readonly>
        <div class="input-group-append d-flex in-1">
            <!-- <a mat-button tabIndex="-1" class="i-btn">
                <ion-icon name="close-outline"></ion-icon>
            </a> -->
            <div class="mat-select-arrow-wrapper">
                <div class="mat-select-arrow"></div>
            </div>
        </div>
    </div>
</div>


<ng-template #optionselecttemplate>
    <div class="ab elv">
        <div class="rel">
            <div class="">
                <ng-container
                    *ngFor="let pOrg of preApplication.lstentPreApplicationGroupOrgLink; let orgIndex = index">
                    <ng-container *ngIf="pOrg.isSelected === 'true'">
                        <mat-list>
                            <h3 matSubheader>{{pOrg.productOrganizationName}}</h3>
                            <ng-container
                                *ngFor="let pPro of pOrg.lstentPreApplicationGroupOrgProductLink; let proIndex = index">
                                <mat-list-item>
                                    <mat-checkbox [checked]="pPro.isSelected === 'true'"
                                        (change)="optionSelected($event, proIndex, orgIndex)">
                                        {{pPro.orgProductName}}
                                    </mat-checkbox>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>