import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import {
  AfterViewInit,
  Component,
  HostListener,
  Injectable,
  InjectionToken,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Message } from "../config/app.classes";
import { DataService } from "../services/data/data.service";
import { MessageFilterPipe } from "./message-filter.pipe";

@Component({
  selector: "app-message-box",
  templateUrl: "./message-box.component.html",
  styleUrls: ["./message-box.component.scss"],
})
@Injectable({
  providedIn: 'root'
})
export class MessageBoxComponent implements OnInit, AfterViewInit {
  private overlayRef!: OverlayRef;
  @ViewChild("messageExpanded", { static: false })
  messageExpanded: TemplateRef<any>;
  isExpanded = false;
  closing = false;

  errorMessage = false;
  warningMessage = false;
  infoMessage = false;

  @Input() public bottomValue = "0px";
  @Input() public panelClass = "message-box-expanded";
  @Input() public backdropClass = "bg-message";
  @Input() public hasBackdrop = true;
  @Input() public message = true;

  expandTrueNow = false;
  @Input()
  set expandTrue(expandTrue: boolean) {
    this.expandTrueNow = expandTrue || false;
    if (expandTrue) {
      this.expandMessageBox(0);
    } else {
      this.closeErrorMessageBox();
    }
    // // console.log(expandTrue);
  }
  get expandTrue() {
    return this.expandTrueNow;
  }

  @HostListener("document:keydown", ["$event"]) private handleKeydown(
    event: KeyboardEvent
  ) {
    if (event.key === "Escape") {
      this.detachOverlay();
    }
  }

  constructor(
    private overlay: Overlay,
    private vcr: ViewContainerRef,
    public data: DataService
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    // this.createOverlay();
  }

  expandMessageBox(val) {
    if (this.overlayRef) {
      if (!this.overlayRef.hasAttached()) {
        this.createOverlay(val);
      } else {
        this.allFalse();
        this.detachOverlay();
      }
    } else {
      this.createOverlay(val);
    }

    // this.attachOverlay();
  }

  createOverlay(val) {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically()
      .bottom(this.bottomValue)
      .left("0")
      .right("0");
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: this.hasBackdrop,
      panelClass: this.panelClass,
      backdropClass: this.backdropClass,
    });
    this.overlayRef.backdropClick().subscribe((_) => this.detachOverlay());
    this.setErrorMessageView(val);
    this.attachOverlay();
  }

  setErrorMessageView(val) {
    this.errorMessage = false;
    this.warningMessage = false;
    this.infoMessage = false;
    switch (val) {
      case 0:
        this.errorMessage = true;
        break;
      case 1:
        this.warningMessage = true;
        break;
      case 2:
        this.infoMessage = true;
        break;
      default:
        break;
    }
  }

  attachOverlay(val?): void {
    // this.isOpen = true;
    if (!this.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(
        this.messageExpanded,
        this.vcr
      );
      this.isExpanded = true;
      this.overlayRef.attach(periodSelectorPortal);
    } else {
      this.allFalse();
      this.detachOverlay();
    }
  }

  private detachOverlay(): void {
    // this.isOpen = false;

    if (this.overlayRef?.hasAttached()) {
      this.closing = true;
      setTimeout(() => {
        this.overlayRef.detach();
        this.isExpanded = false;
        this.closing = false;
        setTimeout(() => {
          this.overlayRef.dispose();
        }, 1000);
      }, 600);
    }
  }

  closeErrorMessageBox() {
    this.data.message = new Message();
    this.allFalse();
    this.detachOverlay();
  }

  allFalse() {
    this.errorMessage = false;
    this.warningMessage = false;
    this.infoMessage = false;
  }
}
