<div class="form-group">
    <div class="d-flex align-items-center pb-1">
        <label for="" class="lh15">{{label}} <span class="text-danger" *ngIf="mandatory">*</span></label>
        <div class="ml-auto d-flex align-items-center lh15">
            <div class="mr-2 text-danger lh15" *ngIf="errorText !== ''">
                <small>{{errorText}}</small>
            </div>
            <ng-container *ngIf="!btnClickedNow">
                <a mat-button color="primary" class="i-btn-2"
                    (click)="inputType === 'password' ? inputType = 'text': inputType= 'password'" tabIndex="-1">
                    <span *ngIf="inputType === 'password'">Show</span>
                    <span *ngIf="inputType === 'text'">Hide</span>
                </a>
            </ng-container>
            <div class="ml-2 text-danger lh15" *ngIf="value === '' && errorTrueNow">
                <small>mandatory</small>
            </div>
        </div>
    </div>
    <div class="input-group">
        <ng-container *ngIf="!strength">
            <input appNoEmoji autocomplete="new-password" [type]="inputType" class="form-control" [(ngModel)]="value"
                name="{{name}}" placeholder="{{placeholder}}" (blur)="blured()"
                [ngClass]="{'border-danger': value === '' && errorTrueNow}" [disabled]="btnClickedNow">
        </ng-container>
        <ng-container *ngIf="strength">
            <input appNoEmoji autocomplete="new-password" [type]="inputType" class="form-control" [(ngModel)]="value"
                name="{{name}}" placeholder="{{placeholder}}" [ngClass]="{'border-danger': errorTrueNow}"
                [disabled]="btnClickedNow" placement="bottom" [ngbPopover]="popContent" triggers="manual"
                (focus)="p.open()" (blur)="p.close()" #p="ngbPopover" [autoClose]="false" (blur)="blured()">
        </ng-container>

        <div class="input-group-append in-1" *ngIf="value !== '' && !btnClickedNow">
            <a mat-button class="i-btn" (click)="value = ''" matTooltip="Clear" matTooltipPosition="right"
                tabIndex="-1">
                <!-- <mat-icon>clear</mat-icon> -->
                <ion-icon name="close-outline"></ion-icon>
            </a>
        </div>
    </div>
</div>
<ng-template #popContent>
    <div class="password-cover">
        <h4>Password Strength</h4>
        <mat-password-strength [password]="value" class="green" #passwordComponent [enableLowerCaseLetterRule]="false">
        </mat-password-strength>
        <!-- <div fxLayout="row" fxLayoutGap="10px">
            <div *ngIf="passwordComponent.strength === 100; then done else error">
            </div>
            <ng-template #done>
                <ion-icon name="done-all"></ion-icon>
            </ng-template>
            <ng-template #error> 
                <ion-icon name="close"></ion-icon>
            </ng-template>
            <div>
                <p>Password's strength = {{passwordComponent.strength}} %100</p>
            </div>
        </div> -->
        <mat-password-strength-info [passwordComponent]="passwordComponent"
            [upperCaseCriteriaMsg]="'Atleast one capital letter'" [digitsCriteriaMsg]="'Atleast one number'"
            [specialCharsCriteriaMsg]="'Atleast one special character'" [minCharsCriteriaMsg]="'Atleast 8 characters'">
        </mat-password-strength-info>
    </div>
</ng-template>