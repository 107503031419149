<div class="container-fluid px-0">
    <div class="row relative pt-2">
        <div class="col-xl-9">
            <div class="form-group">
                <div class="d-flex align-item-center h20px">
                    <label class="ellipsis">{{label}} <span class="text-danger" *ngIf="mandatory">*</span></label>
                    <div class="ml-auto text-danger lh18"
                        *ngIf="(mandatory && xFile.fileName === '' && errorTrueNow)|| formateInvalid">
                        <small>mandatory</small>
                    </div>
                </div>
                <div class="d-flex align-item-center" *ngIf="sublabel !== ''">
                    <label class="ellipsis sm-label">{{sublabel}}</label>
                </div>
                <div class="input-group">
                    <input autocomplete="none" type="text" class="form-control" [(ngModel)]="xFile.fileName"
                        name="fileName" readonly
                        [ngClass]="{'border-warn': (mandatory && xFile.fileName === '' && errorTrueNow) || formateInvalid, 'bg-border-warn': errorText !== ''}">
                    <div class="input-group-append d-flex in-1" *ngIf="xFile.fileName !== ''">
                        <a mat-button class="i-btn" (click)="clear()" matTooltip="Clear" matTooltipPosition="right"
                            [ngClass]="{'pe-none': disabledNow}" tabIndex="-1">
                            <ion-icon name="close-outline"></ion-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 pl-0 ">
            <div class="label-space"></div>
            <a mat-raised-button class="outline-btn btn-sm-lh" (click)="triggerClick()" [disabled]="loadingNow"
                [ngClass]="{'pe-none': disabledNow}">
                <ng-container *ngIf="!loadingNow">Select File</ng-container>
                <ng-container *ngIf="loadingNow">
                    <app-dot class="dot-big"></app-dot>
                </ng-container>
            </a>
            <a mat-raised-button class="outline-btn btn-sm-lh ml-3 download-icon" (click)="doDownload()"
                [ngClass]="{'pe-none': data.documentId===0}">
                <span class="fs20px "> <ion-icon name="download-outline"></ion-icon></span>
            </a>
            <!-- <div class=" btn-sm-lh ml-3">
                <a mat-raised-button color="primary" (click)="doDownload()" class="fs20px height-35">

                </a>
            </div> -->

            <!-- <a mat-raised-button class="outline-btn btn-sm-lh ml-3" (click)="doUpload()" [disabled]="loadingNow"
            [ngClass]="{'pe-none': disabledNow}">
            <ng-container *ngIf="!loadingNow">Upload</ng-container>
            <ng-container *ngIf="loadingNow">
                <app-dot class="dot-big"></app-dot>
            </ng-container>
        </a> -->
        </div>
        <div class="col-xl-12 abs" *ngIf="errorText !== ''">
            <p class="text-danger m-0">{{errorText}}</p>
        </div>
    </div>
</div>
<label class="file-upload" #nativeFileUpload>
    <input type="file" #fileInput (change)="loadImageFromDevice($event)" id="file-input" class="hidden"
        [accept]="accept">
</label>