import { Component, OnInit, Input, ViewChild, TemplateRef, ViewContainerRef, EventEmitter, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { PreApplication } from "../../config/app.classes";
import { DropDownService } from "../../services/drop-down/drop-down.service";
import { ValueAccessorBase } from "../value-accessor-base";

@Component({
  selector: 'app-multi-select-product',
  templateUrl: './multi-select-product.component.html',
  styleUrls: ['./multi-select-product.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MultiSelectProductComponent,
    multi: true,
  }],
})

export class MultiSelectProductComponent extends ValueAccessorBase<any> implements OnInit {
  @Input() public label: string = '';
  @Input() public name: string = '';
  @Input() public placeholder: string = '';
  @Input() public mandatory = false;
  @Output() isproductSelect: any = new EventEmitter();
  errorTrueNow = false;

  @Input() set errorTrue(errorTrue: boolean) {
    this.errorTrueNow = errorTrue || false;
  }

  get errorTrue() {
    return this.errorTrueNow;
  }

  btnClickedNow = false;

  @Input() set btnClicked(btnClicked: boolean) {
    this.btnClickedNow = btnClicked || false;
  }

  get btnClicked() {
    return this.btnClickedNow;
  }

  // Specific to template

  preApplication = new PreApplication();
  organizationLength = 0;
  selectedProCount = 0;
  xValue: any = [];
  @Input()
  selectProErrorMsg = '';
  @Output("productError") productError: EventEmitter<any> = new EventEmitter();
  @Input() set dataSet(dataSet: any) {
    this.preApplication = dataSet || new PreApplication();
  }

  get dataSet() {
    return this.preApplication;
  }

  // dropdown template
  @ViewChild('inputbox', {
    static: true
  }

  ) inputbox !: any;
  @ViewChild('optionselecttemplate') optionselecttemplate !: TemplateRef<any>;

  constructor(public dropDownService: DropDownService,
    public vcr: ViewContainerRef) {
    super();
  }

  ngOnInit(): void {
    this.getValue().subscribe((xvalue) => {
      // // console.log(xvalue);
      if (xvalue) {
        this.setOptionSelected();
      }
    }

    );
  }

  async openSelectOptionDialog() {
    await this.checkOrganizationLength();
    if (!this.dropDownService.isOpen && this.organizationLength > 0) {
      this.dropDownService.openDropdown(this.inputbox.nativeElement,
        this.optionselecttemplate,
        this.vcr);
    }
  }

  checkOrganizationLength() {
    return new Promise((resolve) => {
      this.organizationLength = 0;
      this.selectedProCount = 0;
      let orgLink: any = this.preApplication.lstentPreApplicationGroupOrgLink;
      for (let i = 0; i < orgLink.length; i++) {
        if (orgLink[i].isSelected === 'true') {
          this.organizationLength = this.organizationLength + 1;
        }
      }
      resolve(true);
    })
  }

  optionSelected(event: any, proIndex: any, orgIndex: any) {
    debugger
    this.selectedProCount = 0;
    this.isproductSelect.emit(true);
    this.value = "";
    this.xValue = [];
    this.preApplication.premiumAmount = "";
    this.preApplication.sumInsuredAmount = "";
    let productCode = this.preApplication.lstentPreApplicationGroupOrgLink[orgIndex].lstentPreApplicationGroupOrgProductLink[proIndex].productCode;
    let orgLink: any = this.preApplication.lstentPreApplicationGroupOrgLink;

    for (let i = 0; i < orgLink.length; i++) {
      let proLink = orgLink[i].lstentPreApplicationGroupOrgProductLink;

      for (let j = 0; j < proLink.length; j++) {
        this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].isSelected = 'false';
        let newProductCode = this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].productCode;
        if (productCode === newProductCode && event.checked && orgLink[i].isSelected === 'true') {
          this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].isSelected = 'true';
          this.xValue.push(JSON.parse(JSON.stringify(this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j])));
          this.selectedProCount = this.xValue.length;
        }
      }
    }

    if (this.xValue.length > 0 && this.organizationLength === this.selectedProCount) {
      this.productError.emit('');
      this.xValue.forEach(element => {
        if (this.value === "") {
          this.value = element.orgProductName;
        }
        else {
          this.value = this.value + ', ' + element.orgProductName;
        }
      }
      );
    }

    else {
      this.value = "";
      this.selectedProCount = 0;
      this.productError.emit(this.selectProErrorMsg);
    }
  }

  setOptionSelected() {
    this.xValue = [];
    this.value = "";
    let orgLink: any = this.preApplication.lstentPreApplicationGroupOrgLink;

    for (let i = 0; i < orgLink.length; i++) {
      let proLink = orgLink[i].lstentPreApplicationGroupOrgProductLink;

      for (let j = 0; j < proLink.length; j++) {
        if (this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j].isSelected === 'true') {
          this.xValue.push(JSON.parse(JSON.stringify(this.preApplication.lstentPreApplicationGroupOrgLink[i].lstentPreApplicationGroupOrgProductLink[j])));
        }
      }
    }
    debugger
    if (this.xValue.length > 0) {
      this.xValue.forEach(element => {
        if (this.value === "") {
          this.value = element.orgProductName;
        } else {
          this.value = this.value + ', ' + element.orgProductName;
        }
      });
    } else {
      this.value = "";
    }
  }
}