import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectAccessorBase } from '../select-accessor-base';
import { ValueAccessorBase } from '../value-accessor-base';

@Component({
  selector: 'app-select-control',
  templateUrl: './select-control.component.html',
  styleUrls: ['./select-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectControlComponent,
      multi: true,
    },
  ],
})
export class SelectControlComponent
  extends SelectAccessorBase
  implements OnInit, OnDestroy {
  @Input() public label: string = '';
  @Input() public name: string = '';
  @Input() public items = [];
  @Input() public key: string = '';
  @Input() public keyname: string = '';
  @Input() public placeholderLabel: string = '';
  @Input() public defaultOption:string='';
  @Input() public search: boolean = false;
  @Input() public all: boolean = false;
  @Input() public mandatory = false;
  @Output("change") change: EventEmitter<any> = new EventEmitter();

  // this flag to check the value when it is int;
  @Input() public valuetype = 'string';
  // watchValue: any = '';
  localValue = '';

  errorTrueNow = false;
  @Input()
  set errorTrue(errorTrue: boolean) {
    this.errorTrueNow = errorTrue || false;
  }
  get errorTrue() {
    return this.errorTrueNow;
  }

  btnClickedNow = false;
  @Input()
  set btnClicked(btnClicked: boolean) {
    this.btnClickedNow = btnClicked || false;
  }
  get btnClicked() {
    return this.btnClickedNow;
  }

  searchItem = '';

  @Output('onClear') onClear: EventEmitter<any> = new EventEmitter();
  constructor() {
    super();
    // this.watchValue = this.onValueChange().subscribe((newValue) => {
    //   this.valueChanged(newValue);
    // });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    // this.watchValue.unsubscribe();
  }

  valueChanged(newValue) {
    // if (this.valuetype === 'int') {
    //   // console.log('this value is ' + newValue);
    //   if (newValue === 0) {
    //     this.value = '';
    //     setTimeout(() => {
    //       // console.log('this value is ' + this.value);
    //     }, 1000);
    //   }
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    // // console.log(this.value);
  }

  doClear() {
    this.value = '';
    this.onClear.next();
  }
  doChange(event) {
    this.change.emit(event);
  }
}
